import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { FeaturesComponent } from './features/features.component';
import { HowSellerComponent } from './how-seller/how-seller.component';
import { HowBuyerComponent } from './how-buyer/how-buyer.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { ProductCatComponent } from './product-cat/product-cat.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OrgListComponent } from './org-list/org-list.component';
import { ProductListComponent } from './product-list/product-list.component';
import { UpdateAccountInfoComponent } from './update-account-info/update-account-info.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'update-account-demo', component: UpdateAccountInfoComponent },
  // { path: 'user-profile', component: ProfileComponent },
  { path: 'register', component: SignupComponent },
  // { path: 'landing', component: LandingComponent },
  { path: 'how-buyer', component: HowBuyerComponent },
  { path: 'how-seller', component: HowSellerComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'login', component: LoginComponent },
  { path: 'email-verify/:code', component: EmailVerifyComponent },

  { path: 'org-list', component: OrgListComponent },
  { path: 'org-list/:search', component: OrgListComponent },
  
  { path: 'product-list', component: ProductListComponent },
  { path: 'product-list/:search/:cat', component: ProductListComponent },

  { path: 'company', component: CompanyProfileComponent },
  { path: 'company/:search', component: CompanyProfileComponent },

  // { path: 'product', component: ProductCatComponent },
  // { path: 'product/:what', component: ProductCatComponent },
  
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
