import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-how-buyer',
  templateUrl: './how-buyer.component.html',
  styleUrls: ['./how-buyer.component.css']
})
export class HowBuyerComponent implements OnInit {

  constructor(private http: HttpService, private router: Router) { 

    // site hit code
    let rout = this.router.url;
    console.log(rout);
    http.HitPage(rout);
  }

  ngOnInit() {
  }

}
