import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpService } from './http.service';
import { Authdata } from '../classes/authdata.class';
//import printJS from 'print-js';

@Injectable()
export class StaticService {


  constructor() { }

  public copyRightYear: string = new Date().getFullYear().toString();
  public companyName: string = "SC";
  public companyDomain: string = "SC.com";

  public progressColor: string = "primary";
  public progressState: string = "indeterminate"
  // public progressState: string ="buffer"
  public progressValue = 50;
  public bufferValue = 75;

  //////local
  // private apiUrl: string = "http://localhost:64996/api/";
  // private hostUrl: string = "http://localhost:64996/";
  // private uiUrl: string = "http://localhost:4200/#/";
  // private authUiUrl: string = "http://localhost:4000/#/";

  //////server -live
  private apiUrl: string = "https://api.sourcewindow.com/api/";
  private hostUrl: string = "https://api.sourcewindow.com/";
  private uiUrl: string = "https://sourcewindow.com/";
  private authUiUrl: string = "https://auth.sourcewindow.com/#/";

  public attachmentUrl: string = "Attachment";

  private tokenApi: string = "token";
  private grantType: string = "password";

  public applicationListName: string = "APPLICATION_KEY";
  public profileListName: string = "PROFILES_KEY";


  public userNameTitle: string = "UserName";
  public userIdTitle: string = "UserId";

  public selectedApplicationId: string;
  public selectedApplicationName: string;

  public entity: string = "guest";
  public key: string = "321123";

  //#region COMPANY DETAILS
  public getCompanyName(): string {
    return this.companyName;
  }
  public getCompanyDomain(): string {
    return this.companyDomain;
  }
  public getNotFoundError(): string {
    return "Unfortunately, the page you've requested no longer exists. Please contact with " + this.companyName + ", IT Department.";
  }
  //#endregion

  //#region URL DETAILS
  public getHostUrl(): string {
    return this.hostUrl;
  }
  public getApiUrl(): string {
    return this.apiUrl;
  }
  public getAuthUrl(): string {
    return this.authUiUrl;
  }
  public getTokenUrl(): string {
    return this.hostUrl + this.tokenApi;
  }
  public getGrantType(): string {
    return this.grantType;
  }
  public getUIUrl(): string {
    return this.uiUrl;
  }
  //#endregion

  //#region API LIST
  public applicationApi: string = "Application";
  //#endregion

  //#region common validation rules
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public convertToShortString(data: string) {
    var acronym = "";
    if (data && data.length > 0) {
      var matches = data.match(/\b(\w)/g);
      acronym = matches.join('');
      acronym = acronym.toUpperCase();
    }
    return acronym;
  }

  public ConvertToCapitalizeCaseWord(word: string) {
    if (!word) {
      return word;
    }
    else {
      let splited_word = word.split(" ");
      // console.log(splited_word);
      return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }
  }

  public generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public isGuid(stringToTest) {
    if (stringToTest[0] === "{") {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }
  //#endregion

}
