import { Component, OnInit } from '@angular/core';
import { Typemodel } from '../swcommon/classes/typemodel';
import { Searchmodel } from '../swcommon/classes/searchmodel';
import { Observable } from 'rxjs';
import { SwSearchServiceService } from '../swcommon/services/sw-search-service.service';

@Component({
  selector: 'app-product-cat',
  templateUrl: './product-cat.component.html',
  styleUrls: ['./product-cat.component.scss']
})
export class ProductCatComponent implements OnInit {

  term: string = "";
    search_type_id: number = 1; // default 1
    typeList: Typemodel[] = [];
    searcher: Observable<Searchmodel[]>;
    
  constructor(private heroService: SwSearchServiceService) {
    this.typeList = heroService.getTypeList();
    console.log(this.typeList);
   }

  ngOnInit() {
  }

  resultFound: boolean = false;
  clickSearchButton() {
    alert("Unavaiable..");
  }

  // change the query string when the user type
  search(term) {
    // console.log(e);
    // let term = value;
    // let type = this.search_type_id ? this.search_type_id : this.route.snapshot.queryParams.type;
    // this.router.navigate([], { queryParams: { search: term, type: type } });
  }


  onTypeChange(e) {
    // console.log(e.srcElement.value);
    // let trm = this.route.snapshot.queryParams.search;
    // console.log(trm)
    // this.router.navigate([], { queryParams: { search: trm, type: e.srcElement.value } });
  }

}
