import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { StaticService } from '../services/static.service';
import { Orgprofilemodel } from '../classes/orgprofilemodel';
import { Typemodel } from '../swcommon/classes/typemodel';
import { Searchmodel } from '../swcommon/classes/searchmodel';
import { SwSearchServiceService } from '../swcommon/services/sw-search-service.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {

  search$: Observable<string>;

  body_msg: string = "";
  orgProfile: Orgprofilemodel;
  orgProductList: any[] = [];
  orgServiceList: any[] = [];
  orgContactList: any[] = [];

  feedback: number;
  rateing: number;
  displayRating = "0%";

  term: string = "";
  search_type_id: number = 2; // default 1
  typeList: Typemodel[] = [];
  searcher: Observable<Searchmodel[]>;

  loginUrl: string;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService, private stat: StaticService, private heroService: SwSearchServiceService) {
    this.typeList = heroService.getTypeList();
    console.log(this.typeList);

    this.loginUrl = stat.getAuthUrl() + "login";

    // site hit code
    let rout = this.router.url;
    console.log(rout);
    http.HitPage(rout);
  }

  queryParamObj: any = {};
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.queryParamObj = params;
    });
    console.log(this.queryParamObj);

    this.getSearchCompanyProfileByKey(this.queryParamObj.search);
  }

  getSearchCompanyProfileByKey(search: string) {
    this.http.get("CompanyProfile?code=" + search).subscribe(
      d => {
        let res = JSON.parse(JSON.stringify(d));
        console.log(res);

        if (res.RCode && res.RCode == 200) {
          this.body_msg = res.RMessage;
          this.orgProfile = res.RResult.OrgProfile;
          this.orgProductList = res.RResult.OrgProductList;
          this.orgServiceList = [];///res.RResult.OrgServiceList;
          this.orgContactList = res.RResult.OrgContactList;

          this.rateing = res.RResult.OrgRating.Rating;
          if (res.RResult.OrgRating) {
            let cal = (res.RResult.OrgRating.Rating / 5 * 100);
            this.displayRating = cal + "%";

            this.feedback = res.RResult.OrgRating.TotalFeedback;
          }

          // this.orgProfileList = res.RResult;
        } else if (res.RCode && res.RCode == 404) {
          this.body_msg = res.RMessage;
          console.log(res.RMessage);
        } else {
          this.body_msg = res.RMessage;
          console.log(res.RMessage);
        }

      },
      e => {
        this.body_msg = "Error! Server Connection Refused.";
        // alert(this.body_msg);
      }
    );
  }

  activeTab: number = 1;
  tabOpen(index: number) {
    this.activeTab = index;
  }

  requestQuote() {
    alert("Unavaiable..");
  }

}
