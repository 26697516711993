import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Searchmodel } from '../classes/searchmodel';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { HttpService } from 'src/app/services/http.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Typemodel } from '../classes/typemodel';

@Injectable({
  providedIn: 'root'
})
export class SwSearchServiceService {

  dummyList: Searchmodel[] = [];
  dummyTypeList: Typemodel[] = [];
  constructor(private http: HttpClient) { 
    this.dummyList = [
      { id: 11, name: 'Fruits', typeId: 1 },
      { id: 12, name: 'Shirts', typeId: 1 },
      { id: 13, name: 'Machineries', typeId: 1 },
      { id: 1, name: 'Shuruchi Group', typeId: 2 },
      { id: 2, name: 'Electrologik Co.', typeId: 2 },
      { id: 3, name: 'BSRM Group', typeId: 2 },
      { id: 4, name: 'SaRa GROUP', typeId: 2 },
      { id: 18, name: 'Dr IQ', typeId: 1 },
      { id: 19, name: 'Magma', typeId: 1 },
      { id: 20, name: 'Tornado', typeId: 1 }
    ];

    this.dummyTypeList = [
      { value: 1, name: 'Products' },
      { value: 2, name: 'Organizations' }
    ];
  }

  getTypeList(){
    return this.dummyTypeList;
  }

  /* GET heroes whose name contains search term */
  searchSW(term: string, typeId: number): Observable<Searchmodel[]> {
    debugger
    if (!term.trim()) {
      // if not search term, return empty hero array.
      return of([]);
    }
    // return this.http.get<Searchmodel[]>(`${this.heroesUrl}/?name=${term}`).pipe(
    //   tap(_ => this.log(`found heroes matching "${term}"`)),
    //   catchError(this.handleError<Searchmodel[]>('searchHeroes', []))
    // );
    // return this.http.get("Register", obj)
    let result = this.dummyList.filter( it => {
      return it.typeId == typeId && it.name.toLowerCase().includes(term.toLowerCase());
    });
    // console.log(result);
    return of (result);
  }
}
