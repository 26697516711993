import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit {
  focus;
  focus1;

  // @Input() categoryList: any[];
  categoryList: any[];

  constructor(private http: HttpService, private router: Router) {

    this.getCategories();
    // console.log(this.categoryList);
  }

  ngOnInit() {
  }

  // ngOnChanges(data) {
  //   if (data.categoryList && data.categoryList.currentValue) {
  //     this.categoryList = data.categoryList.currentValue;
  //   }
  // }

  goProductSearch(){
    this.router.navigate(["/product-list/"], { queryParams: { search: "", cat: 0 } });
  }

  goProductFilter(catId: number){
    this.router.navigate(["/product-list/"], { queryParams: { search: "", cat: catId } });
  }

  // categoryList: any[] = [];
  top2List: any[] = [];
  big3: any = {};
  big4: any = {};
  last2List: any[] = [];
  getCategories() {
    this.http.get("Home").subscribe(
      (data) => {
        let res = JSON.parse(JSON.stringify(data));
        console.log(res);
        if (res) {
          console.log(res.FeaturedCategoryList);

          // let fltr1 =  res.RResult.filter(x=>x.HeadName.length <= 30);
          // let top2 = [];
          // let last2 = [];
          // if(fltr1.length == 4){
          //   top2.push(fltr1[0]);
          //   top2.push(fltr1[1]);
            
          //   last2.push(fltr1[2]);
          //   last2.push(fltr1[3]);
          // }

          // let fltr2 = res.RResult.filter(x=>x.HeadName.length > 30);
          // let big1 = {};
          // let big2 = {};
          // if(fltr2.length == 2){
          //   big1 = fltr1[0];
          //   big2 = fltr1[1];
          // }

          if(res.FeaturedCategoryList){
            let it= 1;
            res.FeaturedCategoryList.forEach((k) => {
              k["sl"] = it;

              it++;
            });
          }

          this.categoryList = res.FeaturedCategoryList;

        }
        else {
          // alert("Error! " + res.RMessage);
          console.log(res.RMessage);
        }
      }
      , (error) => {
        // alert("Error! Server Connection Refused.");
      }
    );
  }

}
