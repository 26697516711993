import { Component, OnInit } from '@angular/core';
import { Authdata } from '../classes/authdata.class';
import { HttpService } from '../services/http.service';
import { StaticService } from '../services/static.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Searchmodel } from '../swcommon/classes/searchmodel';
import { SwSearchServiceService } from '../swcommon/services/sw-search-service.service';
import { Typemodel } from '../swcommon/classes/typemodel';
import { async } from '@angular/core/testing';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

    title = 'angulartitle';
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;

    countSupplier: number = 0;
    countProduct: number = 0;
    countMonthlyExplorer: number = 0;

    term: string = "";
    search_type_id: number = 1; // default 1
    typeList: Typemodel[] = [];
    searcher: Observable<Searchmodel[]>;
    constructor(private http: HttpService, private stService: StaticService,
        private httpService: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private heroService: SwSearchServiceService) {

        this.typeList = heroService.getTypeList();
        console.log(this.typeList);

        // site hit code
        let rout = this.router.url;
        console.log(rout);
        http.HitPage(rout);


        //
        this.getHomePageData();
    }

    // renderPageContentsFromExternalSource() {
    //     this.httpService.get('src/data/source_window_pc.json').subscribe(data => {
    //         console.log('data', data);
    //     })
    // }

    categoryList: any[] = [];
    getHomePageData() {
        this.http.get("Home").subscribe(
            (data) => {
                let res = JSON.parse(JSON.stringify(data));
                console.log(res);
                console.log(res.CountResultList);

                this.categoryList = res.FeaturedCategoryList;

                this.countSupplier = res.CountResultList ? res.CountResultList.CountSupplier : 0;
                this.countProduct = res.CountResultList ? res.CountResultList.CountProduct : 0;
                this.countMonthlyExplorer = res.CountResultList ? res.CountResultList.CountMonthlyPageExplorer : 0;

            }
            , (error) => {
                console.log("Error! Server Connection Refused.");
            }
        );
    }

    // change the query string when the user type
    search(e) {
        // console.log(e);
        // let term = value;
        // let type = this.search_type_id ? this.search_type_id : undefined;//this.route.snapshot.queryParams.type;
        // this.router.navigate([], { queryParams: { search: term, type: type } });
        this.term = e;
    }

    onTypeChange(e) {
        console.log(e.srcElement.value);
        this.search_type_id = e.srcElement.value ? e.srcElement.value : undefined;
        // let trm = this.route.snapshot.queryParams.search;
        // console.log(trm)
        // this.router.navigate([], { queryParams: { search: trm, type: e.srcElement.value } });
    }

    resultFound: boolean = false;
    clickSearchButton() {
        // take the current search term
        // this.term = this.route.snapshot.queryParams.search ? this.route.snapshot.queryParams.search.value : this.term;
        console.log(this.term);
        // this.search_type_id = this.route.snapshot.queryParams.type;
        // console.log(this.search_type_id);
        console.log(this.search_type_id);

        // let tId: number = this.search_type_id;

        // if (this.search_type_id <= 0 || this.search_type_id == undefined) {
        //     alert("No data found.");
        // } 

        //else {



        // this.searcher = this.route.queryParams.pipe(
        //     // take the search term from the query string
        //     map(query => query.search || ''),

        //     // wait 300ms after each keystroke before considering the term
        //     debounceTime(300),

        //     // ignore new term if same as previous term
        //     distinctUntilChanged(),

        //     // switch to new search observable each time the term changes
        //     switchMap((term: string) => this.heroService.searchSW(term)),
        // );

        // if (this.term && tId) {
        // this.term = this.route.snapshot.queryParams.search.value;
        console.log(this.term);

        // let search_result = this.heroService.searchSW(this.term, tId);
        // console.log(search_result);

        // this.searcher = search_result;

        // this.resultFound = (this.searcher && this.searcher.subscribe.length > 0) ? true : false;

        if (this.search_type_id > 0) {
            if (this.search_type_id == 2) {
                this.router.navigate(['/org-list/'], { queryParams: { search: this.term } });
            }
            else if (this.search_type_id = 1) {
                this.router.navigate(["/product-list/"], { queryParams: { search: this.term, cat: 0 } });
            }
            else {
                alert("Search type should not be empty.");
            }
        }


        // } else {
        //     alert("No data found.");
        // }
        // }
    }

    ngOnInit() {
        // this.renderPageContentsFromExternalSource();

        // this.run();
    }


    // // 
    // // Add something to given element placeholder
    // addToPlaceholder(toAdd, el) {
    //     el.attr('placeholder', el.attr('placeholder') + toAdd);
    //     // Delay between symbols "typing" 
    //     return new Promise(resolve => setTimeout(resolve, 100));
    // }

    // // Cleare placeholder attribute in given element
    // clearPlaceholder(el) {
    //     el.attr("placeholder", "");
    // }

    // // Print given phrases to element
    // printPhrases(phrases, el) {
    //     // For each phrase
    //     // wait for phrase to be typed
    //     // before start typing next
    //     phrases.reduce(
    //         (promise, phrase) => promise.then(_ => this.printPhrase(phrase, el)),
    //         Promise.resolve()
    //     );
    // }

    // // Print one phrase
    // printPhrase(phrase, el) {
    //     return new Promise(resolve => {
    //         // Clear placeholder before typing next phrase
    //         this.clearPlaceholder(el);
    //         let letters = phrase.split('');
    //         // For each letter in phrase
    //         letters.reduce(
    //             (promise, letter, index) => promise.then(_ => {
    //                 // Resolve promise when all letters are typed
    //                 if (index === letters.length - 1) {
    //                     // Delay before start next phrase "typing"
    //                     setTimeout(resolve, 1000);
    //                 }
    //                 return this.addToPlaceholder(letter, el);
    //             }),
    //             Promise.resolve()
    //         );
    //     });
    // }

    // // Start typing
    // run() {
    //     let phrases = [
    //         "Search Website e.g. \"Dancing Cats\"",
    //         "Lorem ipsum dolor sit amet",
    //         "Consectetur adipiscing elit",
    //         "JS is so strange :)"
    //     ];

    //     let el = document.getElementById('searchBox');

    //     this.printPhrases(phrases, el);
    // }


}
