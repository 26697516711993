import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Orgprofilemodel } from '../classes/orgprofilemodel';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { StaticService } from '../services/static.service';
import { SwSearchServiceService } from '../swcommon/services/sw-search-service.service';

@Component({
  selector: 'app-org-list',
  templateUrl: './org-list.component.html',
  styleUrls: ['./org-list.component.css']
})
export class OrgListComponent implements OnInit {

  search$: Observable<string>;

  term: any = "";
  body_msg: string = "";
  orgProfileList: Orgprofilemodel[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService, private stat: StaticService, private heroService: SwSearchServiceService) {

    // site hit code
    let rout = this.router.url;
    console.log(rout);
    http.HitPage(rout);
  }

  queryParamObj: any = {};
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.queryParamObj = params;

      this.term = params.search;
    });
    console.log(this.queryParamObj);

    this.getSearchCompanyProfileList(this.queryParamObj.search);
  }

  getSearchCompanyProfileList(search: string) {
    this.http.get("CompanyProfileList?search=" + search).subscribe(
      d => {
        let res = JSON.parse(JSON.stringify(d));
        console.log(res);

        if (res.RCode && res.RCode == 200) {
          this.body_msg = res.RMessage;
          this.orgProfileList = res.RResult;
        } else if (res.RCode && res.RCode == 404) {
          this.body_msg = res.RMessage;
          console.log(res.RMessage);
        } else {
          this.body_msg = res.RMessage;
          console.log(res.RMessage);
        }

        console.log(this.orgProfileList);
      },
      e => {
        this.body_msg = "Error! Server Connection Refused.";
        // alert(this.body_msg);
      }
    );
  }

  searchBoxRes(data){
    this.router.navigate([], { queryParams: { search: data } });
  }

  clickSearchButton(e){
    // debugger
    if(this.term){
      this.getSearchCompanyProfileList(this.term);
    }
  }

  activeTab: number = 1;
  tabOpen(index: number){
    this.activeTab = index;
  }

  requestQuote() {
    alert("Unavaiable..");
  }

  clearAll(e){
    this.term = "";
    this.getSearchCompanyProfileList("");
  }


  goProductPage(e){
    this.router.navigate(["/product-list/"], { queryParams: { search: "", cat: 0 } });
  }
}
