import { Component, OnInit } from '@angular/core';
import { SwSearchServiceService } from '../../services/sw-search-service.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable, Subject } from 'rxjs';

import {
   debounceTime, distinctUntilChanged, switchMap, map
 } from 'rxjs/operators';
import { Searchmodel } from '../../classes/searchmodel';

@Component({
  selector: 'app-sw-search',
  templateUrl: './sw-search.component.html',
  styleUrls: ['./sw-search.component.scss']
})
export class SwSearchComponent implements OnInit {

  searcher: Observable<Searchmodel[]>;
  term: string = "";

  constructor(private heroService: SwSearchServiceService,
    private router: Router,
    private route: ActivatedRoute) { }

  // change the query string when the user type
  search(term: string): void {
    this.router.navigate([], { queryParams: { search: term } });
  }

  ngOnInit(): void {
    // take the current search term
    this.term = this.route.snapshot.queryParams.search;

    this.searcher = this.route.queryParams.pipe(
      // take the search term from the query string
      map(query => query.search || ''),

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.heroService.searchSW(term, 0)),
    );
  }

}
