import { Component, OnInit } from '@angular/core';
import { StaticService } from '../services/static.service';

@Component({
  selector: 'app-update-account-info',
  templateUrl: './update-account-info.component.html',
  styleUrls: ['./update-account-info.component.css']
})
export class UpdateAccountInfoComponent implements OnInit {
  loginUrl: string;
  constructor(private staticService: StaticService) { 
    this.loginUrl = staticService.getAuthUrl() + "login";
  }

  ngOnInit() {
  }

}
