import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

    constructor(private http: HttpService, private router: Router) {

        // site hit code
        let rout = this.router.url;
        console.log(rout);
        http.HitPage(rout);
    }

    ngOnInit() { }

}
