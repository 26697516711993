import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { FeaturesComponent } from './features/features.component';
import { HttpService } from './services/http.service';
import { HowBuyerComponent } from './how-buyer/how-buyer.component';
import { HowSellerComponent } from './how-seller/how-seller.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { SwcommonModule } from './swcommon/swcommon.module';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { ProductCatComponent } from './product-cat/product-cat.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OrgListComponent } from './org-list/org-list.component';
import { ProductListComponent } from './product-list/product-list.component';
import { UpdateAccountInfoComponent } from './update-account-info/update-account-info.component';

// export function socialConfigs() {    
//   const config = new AuthServiceConfig(    
//     [    
//       {    
//         id: FacebookLoginProvider.PROVIDER_ID,    
//         provider: new FacebookLoginProvider('app -id')    
//       },    
//       {    
//         id: GoogleLoginProvider.PROVIDER_ID,    
//         provider: new GoogleLoginProvider('app-id')    
//       }    
//     ]    
//   );    
//   return config;    
// }

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    FeaturesComponent,
    HowBuyerComponent,
    HowSellerComponent,
    FaqComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    EmailVerifyComponent,
    CompanyProfileComponent,
    ProductCatComponent,
    AboutUsComponent,
    ContactUsComponent,
    OrgListComponent,
    ProductListComponent,
    UpdateAccountInfoComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    SwcommonModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule
  ],
  providers: [
    HttpService,
    // AuthService,    
    // {    
    //   provide: AuthServiceConfig,    
    //   useFactory: socialConfigs    
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
