import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Orgprofilemodel } from '../classes/orgprofilemodel';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { StaticService } from '../services/static.service';
import { SwSearchServiceService } from '../swcommon/services/sw-search-service.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  search$: Observable<string>;

  term: any = "";
  categorySelect: any = "All";
  product_group_id: number = 0;

  body_msg: string = "";
  productList: any[] = [];
  catList: any[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService, private stat: StaticService, private heroService: SwSearchServiceService) {


    this.getLOVChartOfProductList();

    // site hit code
    let rout = this.router.url;
    console.log(rout);
    http.HitPage(rout);
  }

  queryParamObj: any = {};
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.queryParamObj = params;
debugger
      this.term = params.search;
      this.product_group_id = parseInt(params.cat);
    });
    console.log(this.queryParamObj);

    this.getSearchProductList(this.queryParamObj.search, this.queryParamObj.cat);
  }

  onTypeChange(e) {
    console.log(e.srcElement.value);
    this.product_group_id = e.srcElement.value ? e.srcElement.value : undefined;
    // let trm = this.route.snapshot.queryParams.search;
    // console.log(trm)
    // this.router.navigate([], { queryParams: { search: trm, type: e.srcElement.value } });

    if(this.product_group_id>0){
      this.categorySelect = this.catList.find(x=>x.CoPID == this.product_group_id).ParentName;

      this.categorySelect = this.categorySelect.split('->').join(" / ");
    }
  }

  getLOVChartOfProductList() {
    this.http.get("ChartOfProducts").subscribe(
      d => {
        let res = JSON.parse(JSON.stringify(d));
        console.log(res);

        if (res.RCode && res.RCode == 200) {

          // if(res.RResult){
          //   res.RResult.forEach((k) => {
          //     this.catList.push({ value: k.CoPID, name: k.ParentName });
          //   });
          // }

          console.log(res.RResult);

          this.catList = res.RResult;
        }
        else {
          // this.body_msg = res.RMessage;
          console.log(res.RMessage);
        }
      },
      e => {
        this.body_msg = "Error! Server Connection Refused.";
        // alert(this.body_msg);
      }
    );
  }

  getSearchProductList(search: string, cat: number) {
    this.http.get("ProductList?search=" + search+"&cat="+cat).subscribe(
      d => {
        let res = JSON.parse(JSON.stringify(d));
        console.log(res);

        if (res.RCode && res.RCode == 200) {
          this.body_msg = res.RMessage;
          this.productList = res.RResult;

          this.product_group_id = cat;
          let ctName = this.catList.find(x=>x.CoPID == cat).ParentName;
          this.categorySelect = ctName.split('->').join(" / ");

        } else if (res.RCode && res.RCode == 404) {
          this.body_msg = res.RMessage;
          console.log(res.RMessage);
        } else {
          this.body_msg = res.RMessage;
          console.log(res.RMessage);
        }

        console.log(this.productList);
      },
      e => {
        this.body_msg = "Error! Server Connection Refused.";
        // alert(this.body_msg);
      }
    );
  }

  searchBoxRes(data) {
    this.router.navigate([], { queryParams: { search: data, cat: this.product_group_id  } });
  }

  clickSearchButton(e) {
    // debugger
    if (this.term || this.product_group_id > 0) {
      this.getSearchProductList(this.term, this.product_group_id);
    }
  }

  clearAll(e){
    this.term = "";
    this.categorySelect = "All";
    this.product_group_id = 0;
    this.getSearchProductList("", 0);
  }

  goOrgPage(e){
    this.router.navigate(['/org-list/'], { queryParams: { search: "" } });
  }
}
