import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StaticService } from '../services/static.service';
import { HttpService } from '../services/http.service';
import { ContactUsViewModel } from '../classes/contactus.class';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;

  constructor(private stService: StaticService, private router: Router, private http: HttpService, private formBuilder: FormBuilder) {

    // site hit code
    let rout = this.router.url;
    console.log(rout);
    http.HitPage(rout);
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      fullName: [null, Validators.required],
      companyName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      comapnyLocation: [null, Validators.required],
      requestDetails: [null, Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    console.log(this.contactForm.value);
    this.submitContactForm(this.contactForm.value);

    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value));
  }

  submitContactForm(obj: ContactUsViewModel) {
    console.log(obj);

    this.http.post("Contact", obj).subscribe(d => {
      var val = JSON.parse(JSON.stringify(d));
      console.log(val);
      if (val.RCode && val.RCode == 200) {
        console.log(val.RMessage);
        this.clear();

        alert(val.RMessage);
      }
      else if (val.RCode && val.RCode == 406) {
        alert(val.RMessage);
      }
      else {
        alert("Failed to send.");
      }
    }, e => {
      if (e.status == 400 && e.error != null) {
        alert("ERROR! " + e.error.Message);
      } else {
        alert("ERROR! Server Connection Refused.");
      }
    });
  }

  clear() {
    this.contactForm.reset();
  }

}
