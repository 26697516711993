import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';

import { SectionsModule } from '../sections/sections.module';
import { HttpClientModule } from '@angular/common/http';
import { StaticService } from '../services/static.service';
import { HttpService } from '../services/http.service';
import { Authdata } from '../classes/authdata.class';
import { SwcommonModule } from '../swcommon/swcommon.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        SectionsModule,
        SwcommonModule
    ],
    declarations: [ HomeComponent ],
    exports:[ HomeComponent ],
    providers: [StaticService, HttpService, Authdata]
})
export class HomeModule { }
