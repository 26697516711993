import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingComponent } from './components/rating/rating.component';
import { SwSearchComponent } from './components/sw-search/sw-search.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RatingComponent, SwSearchComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
  ]
  ,exports: [RatingComponent, SwSearchComponent]
})
export class SwcommonModule { }
