import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private http: HttpService, private router: Router) { 

    // site hit code
    let rout = this.router.url;
    console.log(rout);
    http.HitPage(rout);
  }

  ngOnInit() {
  }

}
