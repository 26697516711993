import { Component, OnInit } from '@angular/core';
import { StaticService } from '../services/static.service';
import { HttpService } from '../services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Register } from '../classes/register.class';
import { HttpHeaders } from '@angular/common/http';
import { MustMatch } from '../_helpers/must-match.validator';
import { Authdata } from '../classes/authdata.class';
import { PasswordStrengthValidator } from './password-strength.validators';
import { Socialusers } from '../classes/socialusers.class';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

    response;
    socialusers = new Socialusers();

    registerForm: FormGroup;
    submitted = false;
    private guestUser: string;
    private guestPass: string;
    constructor(private stService: StaticService, private http: HttpService, private formBuilder: FormBuilder,
        // public OAuth: AuthService,
        // private SocialloginService: SocialloginService, 
        private router: Router
    ) {
        this.guestUser = stService.entity;
        this.guestPass = stService.key;

        // site hit code
        let rout = this.router.url;
        console.log(rout);
        http.HitPage(rout);
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            userTypeID: [null, Validators.required],
            fullName: [null, Validators.required],
            email: [null, [Validators.required, Validators.email]],
            // ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]+$')]],
            mobileno: [null, [Validators.required, Validators.maxLength(11), Validators.pattern(this.getPhoneRegex())]],
            loginpass: [null, [Validators.required, Validators.minLength(6), PasswordStrengthValidator]],
            confirmPassword: [null, Validators.required],
            acceptPolicyStatus: [true, Validators.required]
        }, {
            validator: MustMatch('loginpass', 'confirmPassword')
        });


        new Authdata().clearData();

        // this.innerAuthRequest();
    }

    // innerAuthRequest(){
    //     this.http.findKeyForSignup()
    //     .subscribe(
    //       (d) => {
    //         // this.staticService.hideProgressbar();
    //         var json = JSON.parse(JSON.stringify(d));
    //         console.log(json);
    //         var authData = new Authdata();
    //         console.log(authData);
    //         authData.setData(JSON.stringify(json));
    //       }
    //       , (error) => {
    //         console.log("Error1!");
    //       }
    //       , () => {
    //         console.log("Error2!");
    //       }
    //     );
    // }

    getPhoneRegex() {
        return /^(?:\+88|01)?(?:\d{11}|\d{13})$/;
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        console.log(this.registerForm.value);
        this.register(this.registerForm.value);

        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value));
    }

    onResendSubmit() {
        console.log("yes");
        console.log(this.returnResult);

        this.resendVerificationEmail(this.returnResult);
    }

    isDisabled: boolean = false;
    defaultType: number = 578;
    returnResult: Register;
    loading = false; // Add this line
    // message: string;
    register(obj: Register) {
        this.loading = true; // Add this line
        // when save/update button will click
        this.http.post("Register", obj).subscribe(d => {
            this.loading = false; // And this one
            var val = JSON.parse(JSON.stringify(d));
            console.log(val);
            if (val.RCode && val.RCode == 200) {
                console.log(val.RMessage);
                // this.clear();

                this.isDisabled = true;

                this.returnResult = val.RResult;

                alert(val.RMessage);
            }
            else if (val.RCode && val.RCode == 406) {
                alert(val.RMessage);
            }
            else {
                alert("Failed to registration.");
            }
        }, e => {
            this.loading = false; // And this one
            if (e.status == 400 && e.error != null) {
                alert("ERROR! " + e.error.Message);
            } else {
                alert("ERROR! Server Connection Refused.");
            }
        });

        // this.http.findKeyForSignup(obj)
        //     .subscribe(
        //         (d) => {
        //             // this.staticService.hideProgressbar();
        //             var json = JSON.parse(JSON.stringify(d));
        //             console.log(json);
        //             // var authData = new Authdata();
        //             // console.log(authData);
        //             // authData.setData(JSON.stringify(json));
        //             // debugger;

        //             if (json.RCode && json.RCode == 200) {
        //                 console.log(json.RMessage);
        //                 // this.clear();

        //                 this.isDisabled = true;

        //                 this.returnResult = json.RResult;


        //                 alert(json.RMessage);
        //             }
        //             else if (json.RCode && json.RCode == 406) {
        //                 alert(json.RMessage);
        //             }
        //             else {
        //                 alert("Failed to registration.");
        //             }

        //         }
        //         , (error) => {
        //             console.log("faild");
        //         }
        //         , () => {
        //             console.log("connection refused");
        //         }
        //     );
    }

    resendVerificationEmail(obj: Register) {
        this.loading = true; // And this one
        // when save/update button will click
        this.http.post("Register/ResendEmail", obj).subscribe(d => {
            this.loading = false; // And this one
            var val = JSON.parse(JSON.stringify(d));
            console.log(val);
            if (val.RCode && val.RCode == 200) {
                console.log(val.RMessage);
                // this.clear();

                this.returnResult = val.RResult;

                alert(val.RMessage);
                window.open(this.stService.getAuthUrl() + "login", '_self');
            }
            else {
                alert("Unable to send email.");
            }
        }, e => {
            this.loading = false; // And this one
            if (e.status == 400 && e.error != null) {
                alert("ERROR! " + e.error.Message);
            } else {
                alert("ERROR! Server Connection Refused.");
            }
        });
    }

    clear() {
        this.registerForm.reset();
    }
}
